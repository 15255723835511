import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { GetHeaders } from '../../utils';

export function useSlackService() {

    // In case there's an error on the Client Side that's important to track - notifiying via pushing
    // to Slack Channel.
    async function pushErrorNotification({ errorMessage }) {
        try {
            await axios.post(`${API_ENDPOINT.COMMUNICATION_SERVICE}/slack-handler/pushClientErrorNotification`, { errorMessage }, GetHeaders());
        } catch (error) {

        }
    }

    return {
        pushErrorNotification
    }
}