import React, { Suspense, useEffect, useState } from 'react';
import { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import SuspenseFallback from './components/SuspenseFallback';
import { setUnsubscribePopup } from './modules/home/homeSlice';
import { useReducerState } from './utils';

const MobileRouter = lazy(() => import('./mobile/MobileRouter'))
const DesktopRouter = lazy(() => import('./desktop/DesktopRouter'))

function AppRouter() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { state } = useReducerState();
  const onboardingSlice = state?.onboardingReducer

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.search === "?unsubscribe=true") {
      dispatch(setUnsubscribePopup({ unsubscribePopup: true }));
    }
  }, [location.search, dispatch]);

  return (
    <div className={` ${onboardingSlice?.onboardingStatus?.status === false || location?.pathname.includes("/studio") || location?.pathname.includes("/yepp-pdf") ? `h-[100vh]` : `h-[92vh]`}`}>
      <Routes>
        {isMobile ? (
          <Route path="*" element={<Suspense fallback={<SuspenseFallback />}><MobileRouter /></Suspense>} />
        ) : (
          <Route path="*" element={<Suspense fallback={<SuspenseFallback />}><DesktopRouter /></Suspense>} />
        )}

        <Route path="/not-found" element={<p>Not Found</p>} />
      </Routes>
    </div>
  );
}

export default AppRouter;
