import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import store from './redux/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_AUTH_CREDENTIALS } from './config';
import { MyErrorBoundary } from './errorBoundary';
export const IndexContext = React.createContext();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_AUTH_CREDENTIALS.CLIENT_ID}>

    <CookiesProvider >
      <Provider store={store}>
        <BrowserRouter>
          <IndexProvider>
            <MyErrorBoundary>
              <App />
            </MyErrorBoundary>
          </IndexProvider>
        </BrowserRouter>
      </Provider>
    </CookiesProvider >
  </GoogleOAuthProvider>
);

function IndexProvider(props) {
  const [customToast, setCustomToast] = useState({ show: false, message: "" })

  return (
    <IndexContext.Provider value={{
      customToast, setCustomToast
    }}>
      {props.children}
    </IndexContext.Provider>
  );
}
