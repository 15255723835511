import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clientId: null,
    clientRole: null,
    clientName: null,
    clientProfileUrl: null,
    clientUserName: null,
    clientEmailId: null,
    clientAuthStatus: false,
    clientAuthToken: null,
    screenSize: {},
    signupPopup: false,
    emailUpdatesPopup: { popUpStatus: false, incomingEmailId: null },
}

const clientSlice = createSlice({
    name: "clientSlice",
    initialState,
    reducers: {

        setClientDetails_Action: (state, action) => {
            return {
                ...state,
                clientId: action.payload.clientId,
                clientRole: action.payload.clientRole,
                clientName: action.payload.clientName,
                clientProfileUrl: action.payload.clientProfileUrl,
                clientUserName: action.payload.clientUserName,
                clientEmailId: action.payload.clientEmailId,
            }
        },

        setClientUsername: (state, action) => {
            return {
                ...state,
                clientUserName: action.payload.clientUserName
            }
        },

        setClientAuthStatus_Action: (state, action) => {
            return {
                ...state,
                clientAuthStatus: action.payload.clientAuthStatus
            }
        },

        setClientAuthToken_Action: (state, action) => {
            return {
                ...state,
                clientAuthToken: action.payload.clientAuthToken
            }
        },

        setScreenSize: (state, action) => {
            return {
                ...state,
                screenSize: action.payload.screenSize
            }
        },

        setSignupPopup: (state, action) => {
            return {
                ...state,
                signupPopup: action.payload.signupPopup
            }
        },

        setEmailUpdatesPopup: (state, action) => {
            return {
                ...state,
                emailUpdatesPopup:
                {
                    popUpStatus: action.payload.popUpStatus,
                    incomingEmailId: action.payload.incomingEmailId
                }
            }
        },
    }
});

export const {
    setClientDetails_Action,
    setClientAuthStatus_Action,
    setClientAuthToken_Action,
    setScreenSize,
    setSignupPopup,
    setEmailUpdatesPopup,
    setClientUsername
} = clientSlice.actions;

export default clientSlice.reducer;