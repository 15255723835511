import React, { useEffect } from 'react'
import { useToast } from './CustomToast';

function ErrorBoundaryFallback({ error }) {
    const { callToast } = useToast()
    useEffect(() => {
        callToast({
            message: "", type: "error", slackNotificationMessage: `
            Client Component - ErrorBoundaryFallback.jsx\nError Stack - ${error.stack}        
        ` })
    }, [])

    return (
        <>
            <div className='h-screen w-screen bg-black relative'
                style={{ color: "black" }}
            >

                <div className='flex flex-col h-fit w-fit bg-[#FBFAFA] absolute top-0 bottom-0 left-0 right-0 m-auto p-10 items-center'>

                    <p className='text-4xl font-bold mb-4 text-center'>Oops! an error occured</p>
                    <p className='text-lg font-medium text-center'>Please refresh your page, clear the cache or try to login again If the error persists </p>

                    <button
                        className='bg-black h-fit w-fit text-base font-semibold px-5 py-3 rounded-md mt-10'
                        style={{ color: "white" }}
                        onClick={() => {
                            window.location.assign('/');
                        }}
                    >Go to Home Page</button>
                </div>
            </div>
        </>
    )
}

export default ErrorBoundaryFallback