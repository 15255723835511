import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allWorkExperiences: [],
    fetchedWorkExperience: {}
}

const workExperiencesSlice = createSlice({
    name: "workExperiencesSlice",
    initialState,
    reducers: {

        setAllWorkExperiences: (state, action) => {
            state.allWorkExperiences = action.payload.allWorkExperiences
        },

        setFetchedWorkExperience: (state, action) => {
            state.fetchedWorkExperience = action.payload.fetchedWorkExperience
        }
    }
});

export const {
    setAllWorkExperiences,
    setFetchedWorkExperience
} = workExperiencesSlice.actions;

export default workExperiencesSlice.reducer;