import { useDispatch } from "react-redux";
import { setClientAuthStatus_Action, setClientAuthToken_Action, setClientDetails_Action } from "../../redux/clientSlice";
import { Authenticate_User_API_Call, Client_Token_Validity_API_Call } from "./apiService";
import { setAuthenticate_User_API_Call_State_Action, setClient_Token_Validity_API_Call_State_Action } from "./authSlice";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from "react-toastify";
import { API_Call_Status, urls } from "../../constants";
import { API_ENDPOINT } from "src/config";
import { GetHeaders } from "src/utils";
import { useToast } from "src/components/CustomToast";

export function useAuthAPIService() {
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['me']);
    const location = useLocation();
    const navigate = useNavigate();
    const { callToast } = useToast();
    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => authenticateUser({ response: codeResponse, type: "login" }),
        onError: (error) => callToast({ message: "Login Failed!", type: "error" })
    });

    /**
     * Authenticate User
     * type - login | non-login 
     */
    async function authenticateUser({ response, type }) {
        const { user } = await googleAuthService({ access_token: response?.access_token })

        if (user === null) { throw Error("Error") }
        const email = user?.email;
        const name = user?.given_name + " " + user.family_name;
        const profileUrl = user?.picture

        try {

            dispatch(setAuthenticate_User_API_Call_State_Action({ loading: true }))
            const response = await Authenticate_User_API_Call({ email, name, profileUrl });

            // Calculate the expiration date for the cookie (1 week from the current date)
            const oneWeekFromNow = new Date();
            oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
            setCookie("me", response.data.authToken, { path: "/", expires: oneWeekFromNow })

            dispatch(setAuthenticate_User_API_Call_State_Action({
                loading: false,
                success_status: true,
                success_message: "User authentication Successful",
                success_response: response.data
            }))

            dispatch(setClientDetails_Action({
                clientId: response.data.clientId,
                clientRole: response.data.clientRole,
                clientName: response.data.clientName,
                clientProfileUrl: response.data.clientProfileUrl,
                clientUserName: response.data.clientUserName,
                clientEmailId: response.data.clientEmailId
            }))

            dispatch(setClientAuthStatus_Action({
                clientAuthStatus: response.data.clientAuthStatus
            }))

            dispatch(setClientAuthToken_Action({
                clientAuthToken: response.data.authToken
            }))

            await clientTokenValidity({ authToken: response.data.authToken })

            if (type === "login") {
                navigate(urls({}).desktop.studio.basicDetails)
            }

            else {
                window.location.assign(`${location?.pathname}`)
            }

        } catch (error) {
            dispatch(setAuthenticate_User_API_Call_State_Action({
                loading: false,
                error_status: true,
                error_message: "User authentication Failed",
                error_response: error.toString()
            }))
            toast.error("Something went wrong!")
        }
    }

    /**
     * Client Token Validity
     */
    async function clientTokenValidity({ authToken }) {

        try {

            dispatch(setClient_Token_Validity_API_Call_State_Action({ loading: true, api_call_status: API_Call_Status.ONGOING }));
            const response = await Client_Token_Validity_API_Call({ authToken });

            dispatch(setClient_Token_Validity_API_Call_State_Action({
                loading: false,
                success_status: true,
                success_message: "Client Token Validity API call Successful",
                success_response: response.data,
                api_call_status: API_Call_Status.COMPLETED
            }));

            dispatch(setClientAuthStatus_Action({
                clientAuthStatus: response.data.clientAuthStatus
            }))

            dispatch(setClientDetails_Action({
                clientId: response.data.clientId,
                clientRole: response.data.clientRole,
                clientName: response.data.clientName,
                clientProfileUrl: response.data.clientProfileUrl,
                clientUserName: response.data.clientUserName,
                clientEmailId: response.data.clientEmailId
            }))

        } catch (error) {
            dispatch(setClient_Token_Validity_API_Call_State_Action({
                loading: false,
                error_status: true,
                error_message: "Client Token Validity API call Failed",
                error_response: error.toString(),
                api_call_status: API_Call_Status.COMPLETED
            }));
            toast.error("Something went wrong!")
        }

    }

    /**
     * Generate OTP
     */
    async function generateOTPService({ email, setLoading }) {

        try {
            setLoading(true);
            await axios.get(`${API_ENDPOINT.USER_SERVICE}/auth-handler/generate-otp?email=${email}`
                , GetHeaders());
            setLoading(false)
            callToast({ message: "OTP has been sent to your Email ID", type: "success" })
        } catch (error) {
            setLoading(false)
            callToast({ message: "Failed to generate OTP", type: "error" })
        }
    }


    // Getting User's Data from Access Token
    async function googleAuthService({ access_token }) {
        try {
            const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    Accept: 'application/json'
                }
            });

            return { user: response.data }
        } catch (error) {
            return { user: null, error: error.toString() }
        }
    }

    /**
     * Authenticating User Based on OTP
     */
    async function OtpBasedAuthenticationService({ email, otp, setLoading, type }) {
        try {
            setLoading(true);
            const response = await axios.post(`${API_ENDPOINT.USER_SERVICE}/auth-handler/otp-based-authentication`,
                { email, otp }, GetHeaders());
            setLoading(false)

            // Calculate the expiration date for the cookie (1 week from the current date)
            const oneWeekFromNow = new Date();
            oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
            setCookie("me", response.data.authToken, { path: "/", expires: oneWeekFromNow })

            dispatch(setAuthenticate_User_API_Call_State_Action({
                loading: false,
                success_status: true,
                success_message: "User authentication Successful",
                success_response: response.data
            }))

            dispatch(setClientDetails_Action({
                clientId: response.data.clientId,
                clientRole: response.data.clientRole,
                clientName: response.data.clientName,
                clientProfileUrl: response.data.clientProfileUrl,
                clientUserName: response.data.clientUserName,
                clientEmailId: response.data.clientEmailId
            }))

            dispatch(setClientAuthStatus_Action({
                clientAuthStatus: response.data.clientAuthStatus
            }))

            dispatch(setClientAuthToken_Action({
                clientAuthToken: response.data.authToken
            }))

            await clientTokenValidity({ authToken: response.data.authToken })

            if (type === "login") {
                navigate(urls({}).desktop.studio.basicDetails)
            }

            else {
                window.location.assign(`${location?.pathname}`)
            }

        } catch (error) {
            setLoading(false)
            dispatch(setAuthenticate_User_API_Call_State_Action({
                loading: false,
                error_status: true,
                error_message: "User authentication Failed",
                error_response: error.toString()
            }))
            callToast({ message: "Either entered OTP is Incorrect or Expired!", type: "error" })
        }

    }

    return {
        authenticateUser,
        clientTokenValidity,
        googleLogin,
        googleAuthService,
        generateOTPService,
        OtpBasedAuthenticationService
    }
}