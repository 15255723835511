import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    basicDetails: {}

}

const basicDetailsSlice = createSlice({
    name: "basicDetailsSlice",
    initialState,
    reducers: {
        setBasicDetails: (state, action) => {
            state.basicDetails = action.payload.basicDetails
        },

        updateName: (state, action) => {
            state.basicDetails = { ...state.basicDetails, name: action.payload.name }
        },

        updateAbout: (state, action) => {
            return {
                ...state,
                basicDetails: { ...state.basicDetails, title: action.payload.about }
            }
        },

        updateJobTitle: (state, action) => {
            return {
                ...state,
                basicDetails: { ...state.basicDetails, jobTitle: action.payload.jobTitle }
            }
        },

        updateUsername: (state, action) => {
            return {
                ...state,
                basicDetails: { ...state.basicDetails, userName: action.payload.username }
            }
        },

        updateContactNumber: (state, action) => {
            return {
                ...state,
                basicDetails: { ...state.basicDetails, contactNumber: action.payload.contactNumber }
            }
        },

        updateLanguagesList: (state, action) => {
            return {
                ...state,
                basicDetails: { ...state.basicDetails, userLanguages: action.payload.userLanguages }
            }
        },

        updateSocialMediaList: (state, action) => {
            return {
                ...state,
                basicDetails: { ...state.basicDetails, socialMediaUrls: action.payload.socialMediaUrls }
            }
        }

    }
});

export const {
    setBasicDetails,
    updateName,
    updateAbout,
    updateUsername,
    updateContactNumber,
    updateLanguagesList,
    updateSocialMediaList,
    updateJobTitle
} = basicDetailsSlice.actions;

export default basicDetailsSlice.reducer;