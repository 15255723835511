import { createSlice } from '@reduxjs/toolkit';
import { API_Call_Status } from '../../constants';
export const API_State_Object = {
    loading: false,
    api_call_status: API_Call_Status.PENDING,

    success_status: false,
    success_message: null,
    success_response: null,

    error_status: false,
    error_message: null,
    error_response: null,
};

const initialState = {
    Profile_Info_API_Call_State: { ...API_State_Object, loading: true },

    // profileInfo
    Public_Recommendations_List_API_Call_State: API_State_Object,
    Create_Recommendation_API_Call_State: API_State_Object,
    Recommendation_Details_API_Call_State: API_State_Object,
    writeARecommendationOverlay: false,
    recommendationOverlay: false,
    Selected_Recommendation_Block_Id: null,
    Relationship_Drop_Down_State: false,

    recommendationSharePopup: false,
    profileSharePopup: false,
    contactMePopup: false,
    addRecommendation: false,
    projects: [],
    educations: [],
    workExperiences: [],
    skills: [],
    languages: [],
    certificates: []
};

const profileSlice = createSlice({
    name: "profileSlice",
    initialState,
    reducers: {
        setprofile_Info_API_Call_State_Action: (state, action) => {
            return {
                ...state,
                Profile_Info_API_Call_State: {
                    ...state.Profile_Info_API_Call_State,
                    loading: action.payload.loading,

                    success_status: action.payload.success_status,
                    success_message: action.payload.success_message,
                    success_response: action.payload.success_response,

                    error_status: action.payload.error_status,
                    error_message: action.payload.error_message,
                    error_response: action.payload.error_response,
                }
            }
        },

        setpublic_Recommendations_List_API_Call_State_Action: (state, action) => {
            return {
                ...state,
                Public_Recommendations_List_API_Call_State: {
                    ...state.Public_Recommendations_List_API_Call_State,
                    loading: action.payload.loading,

                    success_status: action.payload.success_status,
                    success_message: action.payload.success_message,
                    success_response: action.payload.success_response,

                    error_status: action.payload.error_status,
                    error_message: action.payload.error_message,
                    error_response: action.payload.error_response,
                }
            }
        },

        setcreate_Recommendation_API_Call_State_Action: (state, action) => {
            return {
                ...state,
                Create_Recommendation_API_Call_State: {
                    ...state.Create_Recommendation_API_Call_State,
                    loading: action.payload.loading,

                    success_status: action.payload.success_status,
                    success_message: action.payload.success_message,
                    success_response: action.payload.success_response,

                    error_status: action.payload.error_status,
                    error_message: action.payload.error_message,
                    error_response: action.payload.error_response,
                }
            }
        },

        setrecommendation_Details_API_Call_State_Action: (state, action) => {
            return {
                ...state,
                Recommendation_Details_API_Call_State: {
                    ...state.Recommendation_Details_API_Call_State,
                    loading: action.payload.loading,

                    success_status: action.payload.success_status,
                    success_message: action.payload.success_message,
                    success_response: action.payload.success_response,

                    error_status: action.payload.error_status,
                    error_message: action.payload.error_message,
                    error_response: action.payload.error_response,
                }
            }
        },



        setwrite_A_Recommendation_Overlay_Action: (state, action) => {
            return {
                ...state,
                writeARecommendationOverlay: action.payload.writeARecommendationOverlay
            }
        },

        setrecommendation_Overlay_Action: (state, action) => {
            return {
                ...state,
                recommendationOverlay: action.payload.recommendationOverlay
            }
        },

        setselected_Recommendation_Block_Id_Action: (state, action) => {
            return {
                ...state,
                Selected_Recommendation_Block_Id: action.payload.Selected_Recommendation_Block_Id
            }
        },

        setrelationship_Drop_Down_State_Action: (state, action) => {
            return {
                ...state,
                Relationship_Drop_Down_State: action.payload.Relationship_Drop_Down_State
            }
        },

        setRecommendationSharePopup: (state, action) => {
            return {
                ...state,
                recommendationSharePopup: action.payload.recommendationSharePopup
            }
        },

        setProfileSharePopup: (state, action) => {
            return {
                ...state,
                profileSharePopup: action.payload.profileSharePopup
            }
        },

        setContactMePopup: (state, action) => {
            return {
                ...state,
                contactMePopup: action.payload.contactMePopup
            }
        },

        setAddRecommendation: (state, action) => {
            return {
                ...state,
                addRecommendation: action.payload.addRecommendation
            }
        },

        setProjects: (state, action) => {
            return {
                ...state,
                projects: action.payload.projects
            }
        },

        setWorkExperiences: (state, action) => {
            return {
                ...state,
                workExperiences: action.payload.workExperiences
            }
        },

        setEducations: (state, action) => {
            return {
                ...state,
                educations: action.payload.educations
            }
        },

        setSkills: (state, action) => {
            return {
                ...state,
                skills: action.payload.skills
            }
        },

        setLanguages: (state, action) => {
            return {
                ...state,
                languages: action.payload.languages
            }
        },

        setCertificates: (state, action) => {
            return {
                ...state,
                certificates: action.payload.certificates
            }
        },



    }
});

export const {
    setprofile_Info_API_Call_State_Action,
    setpublic_Recommendations_List_API_Call_State_Action,
    setwrite_A_Recommendation_Overlay_Action,
    setcreate_Recommendation_API_Call_State_Action,
    setrecommendation_Overlay_Action,
    setrecommendation_Details_API_Call_State_Action,
    setselected_Recommendation_Block_Id_Action,
    setrelationship_Drop_Down_State_Action,
    setRecommendationSharePopup,
    setProfileSharePopup,
    setContactMePopup,
    setAddRecommendation,
    setProjects,
    setWorkExperiences,
    setEducations,
    setSkills,
    setLanguages,
    setCertificates
} = profileSlice.actions;

export default profileSlice.reducer;

