import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sideBarOption: { index: 1, isExpanded: true },
}

const studioSlice = createSlice({
    name: "studioSlice",
    initialState,
    reducers: {
        setSideBarOption: (state, action) => {
            state.sideBarOption = { ...state.sideBarOption, ...action.payload.sideBarOption }
        },
    }
});

export const {
    setSideBarOption,
    setProfessionalAssetsChild,
    setloadingState
} = studioSlice.actions;

export default studioSlice.reducer;