
export const env = process.env.REACT_APP_ENV || "dev";
export const API_ENDPOINT = {
    USER_SERVICE: process.env.REACT_APP_USER_SERVICE || `https://q4i402czv6.execute-api.ap-south-1.amazonaws.com/api/${env}`,
    STUDIO_SERVICE: process.env.REACT_APP_STUDIO_SERVICE || `https://5ojb920n8c.execute-api.ap-south-1.amazonaws.com/api/${env}`,
    COMMUNICATION_SERVICE: process.env.REACT_APP_COMMUNICATION_SERVICE || `https://cwg3fqufl4.execute-api.ap-south-1.amazonaws.com/api/${env}`
}

export const GOOGLE_AUTH_CREDENTIALS = {
    CLIENT_ID: "532567085910-r9bu447vfc6pa5f4rt6f1uq9i8u6h9b3.apps.googleusercontent.com",
    CLIENT_SECRET: "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"
}

export const RECOMMENDATION_SECTION = {
    RECEIVED: "received",
    GIVEN: "given"
}

export const S3_Bucket_User_Name = process.env.REACT_APP_USER_NAME || "S3_Bucket_Yepp_Dev";
export const S3_Bucket_Name = process.env.REACT_APP_S3_BUCKET || "yepp-dev";
export const APP_REGION = process.env.REACT_APP_REGION || 'ap-south-1'
export const IAM_USER_ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY || 'AKIA6D3W3DRDX5U7DXN7'
export const IAM_USER_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY || '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'

export const AWS_CONFIG = {
    bucketName: S3_Bucket_Name,
    region: APP_REGION,
    accessKeyId: IAM_USER_ACCESS_KEY,
    secretAccessKey: IAM_USER_SECRET_ACCESS_KEY,
}
