import { PulseLoader } from "react-spinners";
import { MobileLoader, StudioAssetHomeSkeletonLoader } from "./Loader";

export default function SuspenseFallback() {
    return (<><MobileLoader /></>);
}


// Home pages of Professional Assets & PA Routers
export function PAFallBack() {
    return (<StudioAssetHomeSkeletonLoader />);
}

// Asset Page | Form (Education, Projects...)
export function PAFormFallBack() {
    return (
        <div className='w-full h-full relative'>
            <div className='absolute w-fit h-fit left-0 right-0 m-auto top-0 bottom-0'>
                <PulseLoader size={"6"} />
            </div>
        </div>
    )
}