import React from 'react';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';

export class MyErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Catched Error", error);
    console.error(info.componentStack);
  }

  render() {
    if (this.state.hasError) return <ErrorBoundaryFallback error={this.state.error} />
    else {
      return this.props.children;
    }

  }
}
