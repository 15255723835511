import { createSlice } from '@reduxjs/toolkit';
import { API_State_Object } from '../profile/profileSlice';

const initialState = {
    Authenticate_User_API_Call_State: API_State_Object,
    Client_Token_Validity_API_Call_State: API_State_Object
}

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {

        setClient_Token_Validity_API_Call_State_Action: (state, action) => {
            return {
                ...state,
                Client_Token_Validity_API_Call_State: {
                    loading: true,
                    ...state.Client_Token_Validity_API_Call_State,
                    ...action.payload
                }
            }
        },


        setAuthenticate_User_API_Call_State_Action: (state, action) => {
            return {
                ...state,
                Authenticate_User_API_Call_State: {
                    ...state.Authenticate_User_API_Call_State,
                    ...action.payload
                }
            }
        }

    }
});

export const {
    setClient_Token_Validity_API_Call_State_Action,
    setAuthenticate_User_API_Call_State_Action
} = authSlice.actions;

export default authSlice.reducer;