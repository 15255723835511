import { configureStore } from '@reduxjs/toolkit';
import clientReducer from "../redux/clientSlice";
import authReducer from "../modules/auth/authSlice"
import profileReducer from "../modules/profile/profileSlice";
import homeReducer from "../modules/home/homeSlice"
import onboardingReducer from "../modules/home/slices/onboardingSlice";
import projectReducer from "../modules/project/projectSlice";


// Studio Reducers (for different modules)
import studioV2Reducer from "../redux/studio/studioSlice";
import basicDetailsReducer from "../redux/studio/basicDetailsSlice";
import projectsReducer from "./studio/projectsSlice";
import educationsReducer from "./studio/educationsSlice";
import workExperiencesReducer from "./studio/workExperiencesSlice";
import skillsReducer from "./studio/skillsSlice";


const store = configureStore({
    reducer: {
        clientReducer,
        authReducer,
        profileReducer,
        homeReducer,
        onboardingReducer,
        projectReducer,
        studioV2Reducer,
        basicDetailsReducer,
        projectsReducer,
        educationsReducer,
        workExperiencesReducer,
        skillsReducer
    },
    devTools: true, // disables redux devtools in production (use node env vars)
});

export default store;