import { createSlice } from '@reduxjs/toolkit';
import { API_State_Object } from '../../profile/profileSlice';

const initialState = {
    onboardingStatus: { status: null, index: null },
    resumeS3Link: null,

    sectionIndex: 0,
    extractedResumeSections: [],
    extractedSectionGlobalIndex: 0,

    extractResumeService: API_State_Object,
    transformResumeService: API_State_Object,
    getResumeService: API_State_Object,
    userAllProjects: [],
    userAllEducations: [],
    userAllWorkExperiences: [],
    userAllSkills: [],
    userProfileInformation: {}

};


const onboardingSlice = createSlice({
    name: "onboardingSlice",
    initialState,
    reducers: {
        setOnboardingStatus: (state, action) => {
            return {
                ...state,
                onboardingStatus:
                {
                    status: action.payload.status,
                    index: action.payload.index
                }
            }
        },

        setResumeS3Link: (state, action) => {
            return {
                ...state,
                resumeS3Link: action.payload.resumeS3Link
            }
        },

        setSectionIndex: (state, action) => {
            return {
                ...state,
                sectionIndex: action.payload.sectionIndex
            }
        },

        setExtractResume: (state, action) => {
            return {
                ...state,
                extractResumeService: {
                    ...state.extractResumeService,
                    ...action.payload
                }
            }
        },

        setTransformResume: (state, action) => {
            return {
                ...state,
                transformResumeService: {
                    ...state.transformResumeService,
                    ...action.payload
                }
            }
        },

        setResume: (state, action) => {
            return {
                ...state,
                getResumeService: {
                    ...state.getResumeService,
                    ...action.payload
                }
            }
        },

        setExtractedResumeSections: (state, action) => {
            return {
                ...state,
                extractedResumeSections: action.payload.extractedResumeSections
            }
        },

        setExtractedSectionGlobalIndex: (state, action) => {
            return {
                ...state,
                extractedSectionGlobalIndex: action.payload.extractedSectionGlobalIndex
            }
        },

        // Getting User All Projects (Public & Private)
        setUserAllProjects: (state, action) => {
            return {
                ...state,
                userAllProjects: action.payload.userAllProjects
            }
        },

        setUserAllEducations: (state, action) => {
            return {
                ...state,
                userAllEducations: action.payload.userAllEducations
            }
        },

        setUserAllWorkExperiences: (state, action) => {
            return {
                ...state,
                userAllWorkExperiences: action.payload.userAllWorkExperiences
            }
        },

        setUserAllSkills: (state, action) => {
            return {
                ...state,
                userAllSkills: action.payload.userAllSkills
            }
        },

        setUserProfileInformation: (state, action) => {
            return {
                ...state,
                userProfileInformation: action.payload.userProfileInformation
            }
        }


    }
});

export const {
    setOnboardingStatus,
    setResumeS3Link,
    setExtractResume,
    setTransformResume,
    setResume,
    setSectionIndex,
    setExtractedResumeSections,
    setExtractedSectionGlobalIndex,
    setUserAllProjects,
    setUserAllEducations,
    setUserAllWorkExperiences,
    setUserAllSkills,
    setUserProfileInformation
} = onboardingSlice.actions;

export default onboardingSlice.reducer;