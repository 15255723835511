import figma from "./assets/Figma.svg";
import github from "./assets/GitHub.svg";
import behance from "./assets/Behance.svg";
import youtube from "./assets/YouTube.svg";
import instagram from "./assets/Instagram.svg";
import notion from "./assets/Notion.svg";
import dribble from "./assets/Dribbble.svg";
import linkedin from "./assets/LinkedIn.svg";
import medium from "./assets/Medium.svg";
import website from "./assets/Website.svg"

// Relationship List
export const relationshipList = (recommendeeName) => [
    { index: 0, relationship: `Managed ${recommendeeName} directly` },
    { index: 1, relationship: `Reported directly to ${recommendeeName}` },
    { index: 2, relationship: `Senior to ${recommendeeName} but didn't manage directly`, },
    { index: 3, relationship: `${recommendeeName} was senior to me but didn't manage directly`, },
    { index: 4, relationship: `Worked with ${recommendeeName} in the same group`, },
    { index: 5, relationship: `${recommendeeName} worked with me in different group`, },
    { index: 6, relationship: `${recommendeeName} worked with me but at different companies`, },
    { index: 7, relationship: `${recommendeeName} was a client of me`, },
    { index: 8, relationship: `I was a client of ${recommendeeName}`, },
    { index: 9, relationship: `I taught ${recommendeeName}`, },
    { index: 10, relationship: `I mentored ${recommendeeName}`, },
    { index: 11, relationship: `Me & ${recommendeeName} studied together` },
]

export const urls = ({ username, recommendationId, projectId }) => {

    return {
        mobile:
        {
            profileUrl: `/mobile/profile/${username}`,
            onboarding: {
                home: "/mobile/onboarding",
                profileSection: "/mobile/onboarding/profile-section",
                projectSection: "/mobile/onboarding/project-section",
                workExperienceSection: "/mobile/onboarding/work-experience-section",
                educationSection: "/mobile/onboarding/education-section",
                skillsSection: "/mobile/onboarding/skills-section",

            },
            addRecommendation: `/mobile/profile/${username}/add-recommendation`,
            recommendationPage: `/mobile/recommendation/${recommendationId}`,
            projectPage: `/mobile/p/${projectId}`,
            feedbackPage: `/mobile/feedback`,
            home: `/mobile`
        },

        desktop:
        {
            profileUrl: `/profile/${username}`,
            onboarding: {
                home: "/onboarding",
                profileSection: "/onboarding/profile-section",
                projectSection: "/onboarding/project-section",
                workExperienceSection: "/onboarding/work-experience-section",
                educationSection: "/onboarding/education-section",
                skillsSection: "/onboarding/skills-section",
            },
            addRecommendation: `/profile/${username}/add-recommendation`,
            recommendationPage: `/recommendation/${recommendationId}`,
            projectPage: `/p/${projectId}`,
            feedbackPage: "/feedback",
            home: `/`,
            studio: {
                professionalAssets: "/studio",
                basicDetails: "/studio/basic-details",
                projects: "/studio/projects",
                educations: "/studio/educations",
                myRecommendations: "/studio/my-recommendations",
                workExperiences: "/studio/work-experiences",
                skills: "/studio/skills",
                pdfResume: "/studio/pdf-resume",
                certifications: "/studio/achievements-and-certifications",
                jobProfile: "/studio/job-profile"
            },
        },

        notFound: `/not-found`
    }

}

export const resumeTransformationPrompt = "Can you split the below text into sections.? Please structure the output in an array of Java Script objects with keys in human-readable format."


export const API_Call_Status = {
    PENDING: "pending",
    ONGOING: "ongoing",
    COMPLETED: "completed"
}


// Studio Module
export const studioSideBarIndex = ({ child, parent }) => {
    const childList = {
        "Professional Assets": [
            { index: 1, option: "Basic Details", url: urls({}).desktop.studio.basicDetails },
            { index: 2, option: "Projects", url: urls({}).desktop.studio.projects },
            { index: 3, option: "Educations", url: urls({}).desktop.studio.educations },
            { index: 4, option: "Work Experiences", url: urls({}).desktop.studio.workExperiences },
            { index: 5, option: "Skills", url: urls({}).desktop.studio.skills },
            { index: 6, option: "Achievements & Certifications", url: urls({}).desktop.studio.certifications },
            // { index: 6, option: "My Recommendations", url: urls({}).desktop.studio.myRecommendations }
        ],

        "PDF Resume": [
            { index: 7, option: "PDF Resume", url: urls({}).desktop.studio.pdfResume }
        ]
    }

    const childDetails = childList[parent]?.filter((c) => { return c?.option === child });
    return { ...childDetails[0] }

}

export const studioSideBarList = {
    professionalAssets: {
        parent: "Professional Assets",
        basicDetails: "Basic Details",
        projects: "Projects",
        educations: "Educations",
        workExperiences: "Work Experiences",
        skills: "Skills",
        certifications: "Achievements & Certifications"
        // recommendations: "My Recommendations",
    },

    pdfResume: {
        parent: "PDF Resume",
    }
}

export const sideBarIndex = {
    professionalAssets: 0,
    basicDetails: 1,
    projects: 2,
    educations: 3,
    workExperiences: 4,
    skills: 5,
    certifications: 6,
    pdfResume: 7,
    jobProfile: 8
}

export const socialMediaCollection = ({ key }) => {
    switch (key) {
        case "figma":
            return { icon: figma, text: "Figma" }
        case "github":
            return { icon: github, text: "GitHub" }
        case "behance":
            return { icon: behance, text: "Behance" }
        case "youtube":
            return { icon: youtube, text: "YouTube" }
        case "instagram":
            return { icon: instagram, text: "Instagram" }
        case "notion":
            return { icon: notion, text: "Notion" }
        case "dribble":
            return { icon: dribble, text: "Dribble" }
        case "linkedin":
            return { icon: linkedin, text: "LinkedIn" }
        case "medium":
            return { icon: medium, text: "Medium" }
        case "website":
            return { icon: website, text: "Website" }
        case "Contact":
            return { icon: website, text: "Contact" }
        default:
            return { icon: "", text: "" }
    }

}

// Studio "Contact Details" Page
export const socialMediaOptions = [
    { key: "figma" },
    { key: "github" },
    { key: "behance" },
    { key: "youtube" },
    { key: "instagram" },
    { key: "notion" },
    { key: "dribble" },
    { key: "linkedin" },
    { key: "medium" },
    { key: "website" },
    { key: "Contact" },
]
