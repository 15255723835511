import React, { useEffect, useState } from 'react';
import { getCookie, useReducerState } from './utils';
import { useAuthAPIService } from './modules/auth/utils';
import NavBar from './modules/home/components/NavBar';
import AppRouter from './AppRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import CustomToast from './components/CustomToast';
import { useLocation } from 'react-router-dom';
export const AppContext = React.createContext();

function App() {
  const { clientTokenValidity } = useAuthAPIService();
  const me = getCookie("me");
  const { state } = useReducerState();
  const clientSlice = state?.clientReducer;
  const location = useLocation();

  // Context States
  const [showMobileMenu, setShowMobileMenu] = useState(false) // Mobile Hamburger Menu Bar

  useEffect(() => {
    if (!clientSlice?.clientAuthStatus)
      clientTokenValidity({ authToken: me });
  }, [])

  return (
    <>

      <ContextProvider
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
      >
        <div className='h-screen w-screen relative flex flex-col font-open-sans'>
          {location?.pathname.includes("/yepp-pdf") || state?.onboardingReducer?.onboardingStatus?.status === false
            || location?.pathname.includes("/studio") ? (<></>) : (<NavBar />)
          }
          <AppRouter />
        </div>

        <div className='fixed top-0 right-0 z-[100000000]'>
          <ToastContainer
            theme='dark'
            newestOnTop={true}
            limit={1}
            position={'top-right'}
          />
        </div>

        <CustomToast />
      </ContextProvider>
    </>
  );
}

export function ContextProvider(props) {
  return (
    <AppContext.Provider value={{
      showMobileMenu: props.showMobileMenu,
      setShowMobileMenu: props.setShowMobileMenu

    }}>
      {props.children}
    </AppContext.Provider>
  );
}


export default App;
