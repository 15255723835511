import React, { useContext, useEffect } from 'react'
import { IndexContext } from '..'
import { useState } from 'react';
import info from "../assets/Info.svg"
import success from "../assets/Success.svg"
import error from "../assets/Error.svg"
import { useSlackService } from '../modules/3rd-party-services/slack-service';
import { isEmpty } from '../desktop/modules/studio v2/pdf-resume/templates/2/utils';
import { env } from '../config';

export default function CustomToast() {
    const { customToast, setCustomToast } = useContext(IndexContext);
    const [show, setShow] = useState(false);

    let component;

    useEffect(() => {
        if (customToast?.show) {
            setShow(true);

            const timer = setTimeout(() => {
                setShow(false);
                setCustomToast({ show: false });
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [customToast, setCustomToast]);

    // info, success, error
    switch (customToast?.type) {
        case toastType.INFO:
            component = <Info customToast={customToast} show={show} />;
            break;
        case toastType.SUCCESS:
            component = <Success customToast={customToast} show={show} />;
            break;
        case toastType.ERROR:
            component = <Error customToast={customToast} show={show} />;
            break;
        default:
            component = (<></>);
    }

    return (
        <>
            {customToast?.show && component
            }
        </>
    )
}


function Info({ show, customToast }) {
    return (
        <div className={`z-[1000000000000] flex flex-row justify-center items-center font-semibold text-xs rounded-md fixed bottom-5 left-0 right-0 mx-auto w-fit max-w-[480px] h-fit px-5 py-3 bg-black transition-opacity duration-[1000ms] ${show ? 'opacity-100' : 'opacity-0'}`}>
            <img
                className='h-5 w-5 mr-2'
                alt=''
                src={info}
            />
            <p className="w-fit h-fit text-center font-semibold text-xs font-open-sans">{customToast?.message || "In this example, the PopupInfoMessage component receives a prop called message that represents the text message you want to display in the popup. The popup"}</p>
        </div>
    )
}


function Success({ show, customToast }) {
    return (
        <div className={`z-[1000000000000] flex flex-row justify-center items-center font-semibold text-xs rounded-md fixed bottom-5 left-0 right-0 mx-auto w-fit max-w-[480px] h-fit px-5 py-3 bg-black transition-opacity duration-[1000ms] ${show ? 'opacity-100' : 'opacity-0'}`}>
            <img
                className='h-5 w-5 mr-2'
                alt=''
                src={success}
            />
            <p className="w-fit h-fit text-center font-semibold text-xs font-open-sans">{customToast?.message || "In this example, the PopupInfoMessage component receives a prop called message that represents the text message you want to display in the popup. The popup"}</p>
        </div>
    )
}


function Error({ show, customToast }) {
    return (
        <div className={`z-[1000000000000] flex flex-row justify-center items-center font-semibold text-xs rounded-md fixed bottom-5 left-0 right-0 mx-auto w-fit max-w-[480px] h-fit px-5 py-3 bg-black transition-opacity duration-[1000ms] ${show ? 'opacity-100' : 'opacity-0'}`}>
            <img
                className='h-5 w-5 mr-2'
                alt=''
                src={error}
            />
            <p className="w-fit h-fit text-center font-semibold text-xs font-open-sans">{customToast?.message || "In this example, the PopupInfoMessage component receives a prop called message that represents the text message you want to display in the popup. The popup"}</p>
        </div>
    )
}

export const toastType = { INFO: "info", SUCCESS: "success", ERROR: "error" }

export function useToast() {
    const { setCustomToast } = useContext(IndexContext)
    const { pushErrorNotification } = useSlackService()

    function callToast({ message, type, slackNotificationMessage }) {
        setCustomToast({ show: true, message, type })

        // Trigger Slack Notification with 'slackNotificationMessage' & Type === 'error'
        if (type === "error" && !isEmpty({ value: slackNotificationMessage }) && env === "prod") {
            pushErrorNotification({ errorMessage: slackNotificationMessage })
        }
    }

    return { callToast }
}