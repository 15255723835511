import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allSkills: [],
    fetchedSkill: {}
}

const skillsSlice = createSlice({
    name: "skillsSlice",
    initialState,
    reducers: {

        setAllSkills: (state, action) => {
            state.allSkills = action.payload.allSkills
        },

        setFetchedSkill: (state, action) => {
            state.fetchedSkill = action.payload.fetchedSkill
        }
    }
});

export const {
    setAllSkills,
    setFetchedSkill
} = skillsSlice.actions;

export default skillsSlice.reducer;