import axios from "axios";
import { API_ENDPOINT } from "../../config";

export const Authenticate_User_API_Call = async ({ email, name, profileUrl }) => await axios.post(`${API_ENDPOINT.USER_SERVICE}/auth-handler/authenticate-user`,
    { email, name, profileUrl }
    , {
        headers: {
            'Content-Type': 'application/json',
        },
    })

export const Client_Token_Validity_API_Call = async ({ authToken }) => await axios.get(`${API_ENDPOINT.USER_SERVICE}/auth-handler/client-token-validity`,
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken
        },
    })

