import { Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { AvatarSkeletonLoader } from '../../../components/Loader';
import { useReducerState } from '../../../utils';
import yeppLogo from "../../../assets/yepp_logo_white.svg"
import { urls } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import menu from "../../../assets/Menu.svg"
import { useScreenDimension } from '../../../Hooks';
import { AppContext } from '../../../App';
import SignInOverlay from './SignInOverlay';
import { useToast } from 'src/components/CustomToast';

function NavBar() {
    const { state } = useReducerState();
    const authLoading = state?.authReducer?.Authenticate_User_API_Call_State?.loading;
    const authStatus = state?.clientReducer?.clientAuthStatus;
    const clientTokenAuthLoading = state?.authReducer?.Client_Token_Validity_API_Call_State?.loading;
    const navigate = useNavigate();
    const { screenSize } = useScreenDimension();
    const { setShowMobileMenu } = useContext(AppContext);
    const [signOverlay, setSigninOverlay] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const location = useLocation();

    useEffect(() => {
        if (urlSearchParams.get("signin") === "true") {
            setSigninOverlay(true)
        }

        else {
            if (signOverlay)
                setSigninOverlay(false)
        }

    }, [location])

    return (
        <>
            <div className={`w-full sticky top-0 flex flex-row z-50 bg-white text-[black]`}
                style={{ height: "8vh", borderBottom: "1px solid rgba(0, 0, 0,0.2)", color: "white" }}>

                <div className='flex flex-row absolute left-3 lg:left-6 top-0 bottom-0 m-auto items-center'>
                    <Logo />

                    {screenSize?.dynamicWidth > 528 && <>
                        <Option option={"Feedback"} onClick={() => {
                            navigate(urls({}).mobile.feedbackPage)
                        }} />
                        <Option
                            option={"Review My Resume"}
                            onClick={() => {
                                navigate("/?review-resume=true");
                            }
                            }
                        />

                        {authStatus && <Option
                            option={"Go to Studio"}
                            onClick={() => {
                                navigate("/studio/basic-details");
                            }
                            }
                        />}

                    </>}
                </div>

                {authLoading || clientTokenAuthLoading ? (<AvatarSkeletonLoader />) : (
                    authStatus ? (
                        <div className='flex flex-row absolute top-0 bottom-0 m-auto right-0 items-center'>
                            {screenSize?.dynamicWidth <= 528 && <img
                                onClick={() => {
                                    setShowMobileMenu((prevState) => { return !prevState })
                                }}
                                className='mr-2 cursor-pointer'
                                alt='Menu Icon'
                                src={menu}
                            />}

                            <Link to={"/studio"}>
                                <img
                                    alt=''
                                    className='h-10 w-10 rounded-full mr-2 md:mr-6 object-cover'
                                    src={`${state?.clientReducer?.clientProfileUrl}?timestamp=${Date.now()}`}
                                />
                            </Link>
                        </div>
                    ) : (<SigninCTA setSigninOverlay={setSigninOverlay} />)

                )}
            </div>



            {signOverlay && <SignInOverlay setSigninOverlay={setSigninOverlay} />}
        </>
    )
}

export default NavBar

function Logo() {
    return (
        <Tooltip title="Build Your Social Capital">
            <Link to={"/"}>
                <div className='flex flex-row h-fit items-center cursor-pointer text-[black] mr-5'>
                    < img className='mr-0 h-[30px] w-[30px]'
                        src={yeppLogo}
                        alt="Yepp Logo"
                    />

                    <p className='text-base font-bold font-sans h-fit w-fit'>
                        Yepp
                    </p>
                    <p className='text-xs font-bold font-sans mx-2 h-fit w-fit'
                    >Beta</p>
                </div>
            </Link>
        </Tooltip>
    )
}

function SigninCTA({ setSigninOverlay }) {
    const location = useLocation();
    const { callToast } = useToast()
    const navigate = useNavigate();
    return (
        <button
            id="buttonDiv"
            className='h-fit w-fit bg-black text-white text-[14px] border border-[black] lg:text-[14px] font-bold rounded-full absolute top-0 bottom-0 my-auto right-3 lg:right-6 z-50 px-8 py-2'
            onClick={() => {
                if (location.pathname.includes("mobile")) {
                    callToast({ message: "Yepp is available on Desktop", type: "error" })
                }
                else {
                    navigate("?signin=true")
                    setSigninOverlay(true)
                }

            }}
        > Sign In</button >
    )
}


function Option({ option, onClick }) {
    return (
        <p
            onClick={() => {
                if (onClick !== null)
                    onClick()
            }}
            className='text-black font-semibold text-[14px] w-fit h-fit mr-5 cursor-pointer'>{option}</p>
    )
}


