import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setScreenSize } from './redux/clientSlice';


export function useScreenDimension() {
    const dispatch = useDispatch();
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    useEffect(() => {
        setDimension();
    }, [])

    const setDimension = () => {

        dispatch(setScreenSize({
            screenSize: {
                dynamicWidth: window.innerWidth,
                dynamicHeight: window.innerHeight
            }
        }))

        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])


    return {
        screenSize
    }
}