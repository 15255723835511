import { useAuthAPIService } from "src/modules/auth/utils";
import Google from "src/assets/Google.svg"
import { useToast } from 'src/components/CustomToast';
import { isEmpty } from 'src/desktop/modules/studio v2/pdf-resume/templates/2/utils';
import { useEffect, useState } from "react";
import close from "src/assets/CloseWhite.svg"
import { useNavigate } from "react-router-dom";

export default function SignInOverlay({ setSigninOverlay }) {

    const [email, setEmail] = useState("");
    const [isOTPGenerated, setIsOTPGenerated] = useState(false);
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate();

    return (
        <div className='h-screen w-screen bg-[#000000ce] fixed top-0 left-0 z-[50] text-[black]'>

            <div className='w-[700px] h-[fit] bg-white rounded-md flex flex-col py-16 pb-10 px-10 mx-auto mt-10 relative'>

                <div
                    onClick={() => {
                        setSigninOverlay(false)
                        navigate("/");
                    }}
                    className="bg-black cursor-pointer rounded-full border border-[white] w-fit h-fit p-2 absolute top-3 right-3">
                    <img
                        className="w-[15px] h-[15px]"
                        alt="Close Icon"
                        src={close}
                    />
                </div>


                <input
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    placeholder='Enter your Email ID'
                    className='w-full h-fit bg-white font-medium text-[14px] outline-none mt-2 border px-3 py-5 rounded-sm'
                    type='email'
                />

                {isOTPGenerated && <SubmitOTP OTP={OTP} setOTP={setOTP} />}

                {isOTPGenerated && <VerifyOTPCTA OTP={OTP} email={email} />}

                {!isOTPGenerated && <SignInWithOTPCTA
                    email={email}
                    setIsOTPGenerated={setIsOTPGenerated}
                />}

                {
                    isOTPGenerated && <ResendOTPCTA email={email} />
                }

                <div className='flex flex-row w-full h-fit my-6 items-center'>
                    <div className='w-[50%] border border-[#c4c4c4] h-[2px]'></div>
                    <p className='font-semibold text-[15px] w-fit text-center mx-4'>Or</p>
                    <div className='w-[50%] border border-[#c4c4c4] h-[2px]'></div>
                </div>

                <GoogleSignIn />
            </div>

        </div>
    )
}

function SubmitOTP({ OTP, setOTP }) {

    return (
        <input
            value={OTP}
            onChange={(e) => {
                setOTP(e.target.value)
            }}
            placeholder='Enter 6 digit OTP'
            className='w-full h-fit bg-white font-semibold text-[15px] outline-none mt-2 border px-3 py-5 rounded-sm'
            type='email'
        />
    )
}

function GoogleSignIn() {
    const { googleLogin } = useAuthAPIService();

    return (
        <div
            onClick={() => {
                googleLogin()
            }}
            className='flex cursor-pointer flex-row w-full h-fit rounded-md items-center py-5  border border-[black] justify-center'>
            <img
                className='h-[25px] w-[25px] mr-1'
                alt='Google Icon'
                src={Google}
            />

            <button className='w-fit  font-semibold text-[15px] text-[black]'>
                Sign in with Google
            </button>
        </div>

    )
}

function VerifyOTPCTA({ OTP, email }) {
    const [loading, setLoading] = useState(false);
    const { callToast } = useToast();
    const { OtpBasedAuthenticationService } = useAuthAPIService();
    return (
        <button
            disabled={loading ? true : false}
            onClick={async () => {
                if (isEmpty({ value: OTP })) {
                    callToast({ message: "Enter the OTP", type: "error" })
                }

                else {
                    OtpBasedAuthenticationService({ email, otp: OTP, setLoading, type: "login" })
                }
            }}
            className='w-full mt-4 text-center rounded-md py-5 uppercase font-semibold text-[15px] bg-[#41872D] text-[white]'>
            {loading ? "Verifying..." : "Verify OTP"}
        </button>
    )
}

function SignInWithOTPCTA({ email, setIsOTPGenerated }) {
    const { callToast } = useToast();
    const { generateOTPService } = useAuthAPIService();
    const [loading, setLoading] = useState(false);

    function isValidEmail(email) {
        // Regular expression pattern for email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Use the test method to check if the email matches the pattern
        return emailPattern.test(email);
    }

    return (
        <button
            disabled={loading ? true : false}
            onClick={async () => {
                const isValid = isValidEmail(email)
                if (!isValid) {
                    callToast({ message: "Enter a valid Email ID", type: "error" })
                }

                else {
                    // API call to generate OTP
                    await generateOTPService({ email, setLoading });
                    setIsOTPGenerated(true)

                }
            }}
            className='w-full mt-4 text-center rounded-md py-5 uppercase font-semibold text-[15px] bg-[#41872D] text-[white]'>
            {loading ? "sending..." : "Sign In with OTP"}
        </button>
    )
}

function ResendOTPCTA({ email }) {
    const [loading, setLoading] = useState(false);
    const { generateOTPService } = useAuthAPIService();
    const [resendFlag, setResendFlag] = useState(false);
    const [counter, setCounter] = useState(30);
    const [resend, setResend] = useState(false);

    useEffect(() => {
        let intervalId;

        if (counter > 0) {
            intervalId = setInterval(() => {
                setCounter(prevCount => prevCount - 1);
            }, 1000);
        } else if (counter === 0) {
            setResend(true);
        }

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);

    }, [counter, resendFlag]);

    return (
        <>
            {
                resend ? <button
                    disabled={loading ? true : false}
                    onClick={async () => {

                        // API call to generate OTP
                        await generateOTPService({ email, setLoading });
                        setResendFlag((prevState) => !prevState)
                        setCounter(30)
                        setResend(false);
                    }}
                    className='w-full mt-2 text-center uppercase rounded-md py-5 font-semibold text-[15px] bg-[#41872D] text-[white]'>
                    {loading ? "Re-sending..." : `Resend OTP`}
                </button> : <button
                    disabled
                    className='opacity-50 w-full uppercase mt-2 text-center rounded-md py-5 font-semibold text-[15px] bg-[#41872D] text-[white]'>
                    {loading ? "Re-sending..." : `Resend OTP in ${counter}s`}
                </button>
            }

        </>
    )
}