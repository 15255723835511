import { createSlice } from '@reduxjs/toolkit';
import { API_State_Object } from '../profile/profileSlice';

const initialState = {
    subscribeEmailService: API_State_Object,
    unsubscribeEmailService: API_State_Object,

    unsubscribePopup: false
};


const homeSlice = createSlice({
    name: "homeSlice",
    initialState,
    reducers: {
        setSubscribeEmailService: (state, action) => {
            return {
                ...state,
                subscribeEmailService: {
                    ...state.subscribeEmailService,
                    ...action.payload
                }
            }
        },

        setUnsubscribeEmailService: (state, action) => {
            return {
                ...state,
                unsubscribeEmailService: {
                    ...state.unsubscribeEmailService,
                    ...action.payload
                }
            }
        },

        setUnsubscribePopup: (state, action) => {
            return {
                ...state,
                unsubscribePopup: action.payload.unsubscribePopup
            }
        },

    }
});

export const {
    setSubscribeEmailService,
    setUnsubscribeEmailService,
    setUnsubscribePopup
} = homeSlice.actions;

export default homeSlice.reducer;