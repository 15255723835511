export const sectionTypes = {
    header: "header",
    contact: "contact",
    education: "education",
    workExperience: "workExperience",
    project: "project",
    skill: "skill",
    language: "language",
    certificate: "certificate"
}

export function globalToLocalResumeDetails_2({ globalResumeDetails }) {

    if (Object.keys(globalResumeDetails).length === 0) {
        return { details: {} }
    }

    const primaryDetails = globalResumeDetails?.primaryDetails;
    const contactDetails = globalResumeDetails?.contactDetails;

    const projects = globalResumeDetails?.projects;
    const educations = globalResumeDetails?.educations;
    const workExperiences = globalResumeDetails?.workExperiences;
    const skills = globalResumeDetails?.skills;
    const certificates = globalResumeDetails?.certificates;
    const languages = globalResumeDetails?.languages

    const details = {
        header: {
            data: {
                name: primaryDetails?.name,
                jobTitle: primaryDetails?.jobTitle,
                summary: primaryDetails?.about
            }, type: sectionTypes?.header,
        },

        contact: {
            data: [
                ...contactDetails?.socialMedia
            ],
            type: sectionTypes?.contact
        },

        workExperience: {
            data: workExperiences,
            type: sectionTypes?.workExperience
        },

        education: {
            data: educations,
            type: sectionTypes?.education
        },

        project: {
            data: projects,
            type: sectionTypes?.project
        },

        skill: {
            data: skills,
            type: sectionTypes?.skill
        },

        certificate: {
            data: certificates,
            type: sectionTypes?.certificate
        },

        language: {
            data: languages,
            type: sectionTypes?.language
        }
    }


    return { details }

}

/**
 * Convert 'local' resume details object to 'global' resume details object
 * local resume details object can be understand by a specific template
 */
export function localToResumeDetails_2({ localResumeDetails }) {

    const resumeDetails = {};

    const primaryDetails = {};
    const contactDetails = {};

    const header = localResumeDetails?.header?.data;
    const contact = localResumeDetails?.contact?.data;
    const workExperiences = localResumeDetails?.workExperience?.data;
    const languages = localResumeDetails?.language?.data;
    const educations = localResumeDetails?.education?.data;
    const projects = localResumeDetails?.project?.data;
    const skills = localResumeDetails?.skill?.data;
    const certificates = localResumeDetails?.certificate?.data;

    primaryDetails.name = header?.name;
    primaryDetails.about = header?.summary;
    primaryDetails.jobTitle = header?.jobTitle;

    contactDetails.socialMedia = contact;

    resumeDetails.primaryDetails = primaryDetails;
    resumeDetails.contactDetails = contactDetails;
    resumeDetails.certificates = isEmpty({ value: certificates }) ? [] : certificates
    resumeDetails.workExperiences = isEmpty({ value: workExperiences }) ? [] : workExperiences
    resumeDetails.languages = isEmpty({ value: languages }) ? [] : languages
    resumeDetails.educations = isEmpty({ value: educations }) ? [] : educations
    resumeDetails.projects = isEmpty({ value: projects }) ? [] : projects
    resumeDetails.skills = isEmpty({ value: skills }) ? [] : skills

    return { resumeDetails }
}


export function isEmpty({ value }) {
    if (value === undefined || value === null) {
        return true;
    }

    if (typeof value === 'string' && value.trim() === '') {
        return true;
    }

    if (Array.isArray(value) && value.length === 0) {
        return true;
    }

    if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true;
    }

    return false;
}