import { useSelector } from "react-redux"
import { isEmpty } from "./desktop/modules/studio v2/pdf-resume/templates/2/utils";
// import { makeStyles } from '@material-ui/core/styles';

export function useReducerState() {
    const state = useSelector((state) => { return state })
    return { state: state }
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const GetHeaders = () => {
    const token = getCookie("me");
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
    }
}

// sorting list of assets (projects, educations, work experiences)
export function sortAssetBasedOnTimeline({ asset }) {
    const data = asset.slice().sort((a, b) => {
        const startDateA = new Date(a?.timeline?.startDate);
        const startDateB = new Date(b?.timeline?.startDate);
        return startDateB - startDateA;
    });
    return data;
}

export function isNotNullUndefinedOrEmpty(value) {
    return value !== null && value !== undefined && value !== '';
}

export function triggerCustomEvent({ eventName }) {
    const customEvent = new Event(eventName);
    window.dispatchEvent(customEvent);
}



// 'Nov 2023' to 'Date String compaitable with </input type = 'date'>
export function convertStringToDate({ dateString }) {
    const status = isEmpty({ value: dateString });

    if (status || dateString === "Present") return { formattedString: "" }

    const [monthString, yearString] = dateString.split(' ');

    // Convert month string to its numeric representation
    const monthNumber = new Date(Date.parse(`${monthString} 1, 2000`)).getMonth();

    // Create the date object with the first day of the month
    const date = new Date(parseInt(yearString), monthNumber, 1);
    const dateObject = new Date(date);

    const year = dateObject?.getFullYear();
    const month = (dateObject?.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject?.getDate().toString().padStart(2, '0');
    const formattedString = `${year}-${month}-${day}`;

    return { formattedString };
}


export function convertDateToResumeFormat({ dateString }) {
    const dateObject = isEmpty({ value: dateString }) ? null : new Date(dateString);
    if (dateObject === null) return { string: 'Present' }
    const month = dateObject.toLocaleString('default', { month: 'short' });
    const year = dateObject.getFullYear();

    return { string: `${month} ${year}` };
}

export const LOCAL_STORAGE_KEYS = {
    AI_ASSISTANT_ASSET_FEEDBACK_RECOMMENDATION: "AI_ASSISTANT_ASSET_FEEDBACK_RECOMMENDATION",
    SUMMARY_ASSISTANT_AI_RESPONSE: "SUMMARY_ASSISTANT_AI_RESPONSE",
    PROFESSIONAL_TITLE_ASSISTANT_AI_RESPONSE: "PROFESSIONAL_TITLE_ASSISTANT_AI_RESPONSE",
    SKILL_ASSISTANT_AI_RESPONSE: "SKILL_ASSISTANT_AI_RESPONSE",
    PROJECT_ASSISTANT_AI_RESPONSE: "PROJECT_ASSISTANT_AI_RESPONSE",
    WORKEXPERIENCE_ASSISTANT_AI_RESPONSE: "WORKEXPERIENCE_ASSISTANT_AI_RESPONSE",

    /** Local Storage Keys for job-profile > modules > profile-mode Module.  */
    SUMMARY_PROFILE_MODE_ASSISTANT_RESPONSE: "SUMMARY_PROFILE_MODE_ASSISTANT_RESPONSE",
    PROFESSIONAL_TITLE_PROFILE_MODE_ASSISTANT_RESPONSE: "PROFESSIONAL_TITLE_PROFILE_MODE_ASSISTANT_RESPONSE",
    SKILL_PROFILE_MODE_ASSISTANT_RESPONSE: "SKILL_PROFILE_MODE_ASSISTANT_RESPONSE",
    PROJECT_PROFILE_MODE_ASSISTANT_RESPONSE: "PROJECT_PROFILE_MODE_ASSISTANT_RESPONSE",
    WORK_EXPERIENCE_PROFILE_MODE_ASSISTANT_RESPONSE: "WORK_EXPERIENCE_PROFILE_MODE_ASSISTANT_RESPONSE"
}
