import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    allProjects: [],
    fetchedProject: {}

}

const projectsSlice = createSlice({
    name: "projectsSlice",
    initialState,
    reducers: {

        setAllProjects: (state, action) => {
            state.allProjects = action.payload.allProjects
        },

        setFetchedProject: (state, action) => {
            state.fetchedProject = action.payload.fetchedProject
        }


    }
});

export const {
    setAllProjects,
    setFetchedProject
} = projectsSlice.actions;

export default projectsSlice.reducer;