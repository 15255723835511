import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allEducations: [],
    fetchedEducation: {}
}

const educationsSlice = createSlice({
    name: "educationsSlice",
    initialState,
    reducers: {

        setAllEducations: (state, action) => {
            state.allEducations = action.payload.allEducations
        },

        setFetchedEducation: (state, action) => {
            state.fetchedEducation = action.payload.fetchedEducation
        }
    }
});

export const {
    setAllEducations,
    setFetchedEducation
} = educationsSlice.actions;

export default educationsSlice.reducer;