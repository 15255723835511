import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectDetails: {}
}

const projectSlice = createSlice({
    name: "projectSlice",
    initialState,
    reducers: {

        setProjectDetails: (state, action) => {
            return {
                ...state,
                projectDetails: action.payload.projectDetails
            }
        }

    }
});

export const {
    setProjectDetails
} = projectSlice.actions;

export default projectSlice.reducer;